"use client";

import * as React from "react";

import { cn } from "@winclap-platform/ui/utils/tailwind";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="border-primary-60 relative w-full border-separate overflow-auto rounded-md border bg-white p-0">
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-xs", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("border-primary-60 border-b [&_tr]:border-0", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn("relative", className)} {...props} />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "bg-gray-50 font-semibold transition-colors hover:bg-gray-100 data-[state=selected]:bg-gray-100 dark:hover:bg-gray-800/50 dark:data-[state=selected]:bg-gray-800",
      className,
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "from-purple-40 to-green-10 border-b border-gray-200 transition-colors data-[state=selected]:bg-gray-100 dark:hover:bg-gray-800/50 dark:data-[state=selected]:bg-gray-800",
      className,
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const SpanRow = React.forwardRef<
  React.ElementRef<typeof TableRow>,
  React.ComponentPropsWithoutRef<typeof TableRow>
>(({ className, children, ...props }, ref) => (
  <>
    <TableRow
      className={cn(
        "pointer-events-none absolute m-auto flex h-64 w-full items-center justify-center",
        className,
        "border-b-0",
      )}
      ref={ref}
      {...props}
    >
      <TableCell>{children}</TableCell>
    </TableRow>
    <TableRow className="pointer-events-none relative flex h-64 w-full items-center justify-center" />
  </>
));
SpanRow.displayName = "SpanRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-12 text-nowrap px-4 text-left align-middle text-sm font-normal dark:text-gray-400 [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "px-4 py-2 align-middle [&:has([role=checkbox])]:pr-0 [&:has(button)]:px-1 [&:has(button)]:py-1.5",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-gray-500 dark:text-gray-400", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  SpanRow,
  TableCell,
  TableCaption,
};
