import {
  RowData,
  Table as ITable,
  flexRender,
  Row,
  Cell,
  Column,
} from "@tanstack/react-table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from "@winclap-platform/ui/components/accordion";
import { Loader } from "@winclap-platform/ui/components/loader";
import {
  SpanRow,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@winclap-platform/ui/components/table";
import { Frown } from "lucide-react";
import { TablePaginator } from "./table-paginator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@winclap-platform/ui/components/tooltip";
import { CSSProperties, useLayoutEffect, useRef, useState, Fragment } from "react";

export interface TableProps<TData extends RowData> {
  table: ITable<TData>;
  isLoading?: boolean;
  accordionContent?: (row: Row<TData>) => React.ReactNode;
  emptyMessage?: string;
  onRowClick?: (row: Row<TData>) => void;
  rowClassName?: (row: Row<TData>) => string;
  tablePaginator?: boolean;
  expandable?: boolean;
}

const getCommonPinningStyles = <TData,>(
  column: Column<TData>,
  table: ITable<TData>,
  isSubrow?: boolean,
): CSSProperties => {
  const isPinned = column.getIsPinned();

  const styles: CSSProperties = {
    position: isPinned ? "sticky" : undefined,
    zIndex: isPinned ? 10 : 1,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getStart("right")}px` : undefined,
    background: isPinned ? (isSubrow ? "#f3f4f6" : "white") : undefined,
    marginRight: isPinned === "right" ? "0" : undefined,
  };

  if (isPinned === "left") {
    const leftPinnedColumns = table.getLeftLeafColumns();
    const isLastLeftPinnedColumn =
      leftPinnedColumns[leftPinnedColumns.length - 1]?.id === column.id;
    styles.boxShadow = `-1px 0 0 0 ${
      isLastLeftPinnedColumn ? "#D8BCFF" : "#E5E7EB"
    } inset`;
  }

  if (isPinned === "right") {
    const rightPinnedColumns = table.getRightLeafColumns();
    const isFirstRightPinnedColumn = rightPinnedColumns[0]?.id === column.id;
    styles.boxShadow = `1px 0 0 0 ${
      isFirstRightPinnedColumn ? "#D8BCFF" : "#E5E7EB"
    } inset`;
  }

  return styles;
};

export const TableLayout = <TData extends RowData>({
  table,
  isLoading,
  accordionContent,
  emptyMessage,
  onRowClick,
  rowClassName,
  expandable = false,
}: TableProps<TData>) => {
  const hasData = table.options.data.length > 0;
  const maxHeaderDepth = table.getHeaderGroups().length;

  const hasFooter = table
    .getFooterGroups()
    .some((footerGroup) =>
      footerGroup.headers.some(({ column }) => !!column.columnDef.footer),
    );

  const lastLeafColumnIds = table
    .getHeaderGroups()[0]
    ?.headers.filter((header) => !header.column.getIsPinned())
    .map((header) => {
      const leafColumns = header.column.getLeafColumns();
      return (
        leafColumns.length > 0 ? leafColumns[leafColumns.length - 1] : header.column
      )?.id;
    });

  return (
    <section className="mb-6 flex h-full flex-col justify-between gap-6">
      {/* Wrap the Table in a relative container */}
      <div className="relative">
        {!hasData && !isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div
              className={`z-10 flex flex-col gap-1 text-center ${maxHeaderDepth > 1 ? "mt-12" : "mt-6"}`}
            >
              <h3 className="text-sm font-semibold">
                <Frown className="mr-2 inline-block" />
                {"We couldn't find any results"}
              </h3>
              <p className="text-sm">
                {emptyMessage || "Please try again with different filters."}
              </p>
            </div>
          </div>
        )}
        <Table className="w-full table-fixed border-collapse bg-white">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  const isLastHeader = index === headerGroup.headers.length - 1;
                  const isLeafHeader = header.subHeaders?.length === 0;
                  const rowSpan = isLeafHeader
                    ? maxHeaderDepth - headerGroupIndex
                    : 1;
                  const isLastHeaderRow = headerGroupIndex === maxHeaderDepth - 1;

                  const nextHeader = headerGroup.headers[index + 1];
                  const isNextColumnPinnedRight =
                    nextHeader?.column.getIsPinned() === "right";

                  const headerClassNames = [
                    "text-left",
                    "px-2",
                    "align-middle",
                    "overflow-hidden",
                    isLeafHeader ? `h-[${25 * rowSpan}px]` : "h-[25px]",
                    !isLastHeaderRow && !isLeafHeader
                      ? "border-b border-gray-200"
                      : "",
                    !isLastHeader &&
                    !header.column.getIsPinned() &&
                    !isNextColumnPinnedRight &&
                    maxHeaderDepth > 1
                      ? "border-r border-gray-200"
                      : "",
                    maxHeaderDepth > 1 ? "py-1" : "py-1.5",
                    "px-4",
                    "m-0",
                    "whitespace-nowrap",
                    header.column.columnDef.meta?.headClassName || "",
                  ]
                    .filter(Boolean)
                    .join(" ");

                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      rowSpan={rowSpan > 1 ? rowSpan : undefined}
                      className={headerClassNames}
                      style={{
                        ...getCommonPinningStyles(header.column, table),
                        width: header.getSize() ? `${header.getSize()}px` : "",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>

          {/* Accordion for expandable rows */}
          <Accordion type="single" collapsible asChild>
            <TableBody>
              {hasData ? (
                table.getRowModel().rows.map((row, rowIndex) => {
                  const nextRow = table.getRowModel().rows[rowIndex + 1];
                  const isSubrow = row.depth > 0;
                  const isLastSubrow =
                    isSubrow && (!nextRow || nextRow.depth <= row.depth - 1);

                  const isLastRow = rowIndex === table.getRowModel().rows.length - 1;

                  const rowClasses = [
                    rowClassName?.(row),
                    isSubrow
                      ? `bg-gray-100 ${!isLastSubrow ? "border-b-0" : "border-b"}`
                      : "",
                    !expandable ? "hover:bg-gradient-to-r" : "",
                  ]
                    .filter(Boolean)
                    .join(" ");

                  if (accordionContent) {
                    return (
                      <Fragment key={row.id}>
                        <AccordionItem value={row.id} asChild className="">
                          <>
                            {/* Main Row */}
                            <TableRow
                              className={`${rowClasses} peer ${
                                isLastRow && !hasFooter
                                  ? "border-b-0"
                                  : "border-b border-gray-200"
                              }`}
                              onClick={() => onRowClick?.(row)}
                            >
                              {row.getVisibleCells().map((cell, index) => {
                                const isLastCell =
                                  index === row.getVisibleCells().length - 1;
                                const cellClassName = [
                                  "h-[48px]",
                                  "min-w-[50px]",
                                  "py-1.5",
                                  expandable && isLastCell
                                    ? "flex items-center justify-between"
                                    : "",
                                ]
                                  .filter(Boolean)
                                  .join(" ");

                                return (
                                  <TruncatedCell
                                    key={cell.id}
                                    cell={cell}
                                    style={{
                                      ...getCommonPinningStyles(
                                        cell.column,
                                        table,
                                        row.depth > 0,
                                      ),
                                      width: cell.column.getSize()
                                        ? `${cell.column.getSize()}px`
                                        : "auto",
                                    }}
                                    className={cellClassName}
                                  />
                                );
                              })}
                            </TableRow>

                            {/* Accordion Content */}
                            <AccordionContent asChild>
                              <TableRow
                                className={`border-0 from-transparent to-transparent ${hasFooter || !isLastRow ? "border-b border-gray-200" : ""}`}
                              >
                                <TableCell colSpan={row.getVisibleCells().length}>
                                  {/* <Separator className="-mt-2" /> */}
                                  {accordionContent(row)}
                                </TableCell>
                              </TableRow>
                            </AccordionContent>
                          </>
                        </AccordionItem>
                      </Fragment>
                    );
                  }
                  return (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                      className={`${rowClasses} ${
                        isLastRow && !hasFooter ? "border-b-0" : ""
                      }`}
                      onClick={() => onRowClick?.(row)}
                    >
                      {row.getVisibleCells().map((cell, index) => {
                        const isLastCell =
                          index === row.getVisibleCells().length - 1;
                        const isLastLeafUnderTopLevel = lastLeafColumnIds?.includes(
                          cell.column.id,
                        );
                        const isPinned = cell.column.getIsPinned();
                        const nextCell = row.getVisibleCells()[index + 1];
                        const isNextColumnPinnedRight =
                          nextCell?.column.getIsPinned() === "right";

                        const cellClassName = [
                          "h-[48px]",
                          "min-w-[50px]",
                          expandable && isLastCell
                            ? "flex items-center justify-between"
                            : "",
                          !isLastCell &&
                          !isPinned &&
                          !isNextColumnPinnedRight &&
                          isLastLeafUnderTopLevel &&
                          maxHeaderDepth > 1
                            ? "border-r border-gray-200"
                            : "",
                        ]
                          .filter(Boolean)
                          .join(" ");

                        return (
                          <TruncatedCell
                            key={cell.id}
                            cell={cell}
                            style={{
                              ...getCommonPinningStyles(
                                cell.column,
                                table,
                                row.depth > 0,
                              ),
                              width: cell.column.getSize()
                                ? `${cell.column.getSize()}px`
                                : "auto",
                            }}
                            className={cellClassName}
                          />
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <SpanRow>{/* Empty state message */}</SpanRow>
              )}
            </TableBody>
          </Accordion>

          {/* Footer section */}
          {table.getRowModel().rows.length > 0 && hasFooter && (
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id} className="last:border-b-0">
                  {footerGroup.headers.map((footer) => (
                    <TableCell
                      key={footer.id}
                      colSpan={footer.colSpan}
                      className="h-[45px] py-2"
                      style={{
                        ...getCommonPinningStyles(footer.column, table),
                        width: footer.column.getSize()
                          ? `${footer.column.getSize()}px`
                          : "auto",
                      }}
                    >
                      {footer.isPlaceholder
                        ? null
                        : flexRender(
                            footer.column.columnDef.footer,
                            footer.getContext(),
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          )}
        </Table>

        {/* Empty state / Loader overlay */}
        {isLoading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <div className="absolute inset-0 bg-white opacity-50"></div>
            <div className="relative z-20">
              <Loader />
            </div>
          </div>
        )}
      </div>

      <TablePaginator table={table} />
    </section>
  );
};

export interface TruncatedCellProps<TData extends RowData> {
  cell: Cell<TData, unknown>;
  className?: string;
  style?: React.CSSProperties;
}

export const TruncatedCell = <TData extends RowData>({
  cell,
  className,
  style,
}: TruncatedCellProps<TData>) => {
  const cellRef = useRef(null);
  const isTruncated = useIsTruncated(cellRef);

  return (
    <TableCell
      key={cell.id}
      style={{ ...style, maxWidth: cell.column.columnDef.maxSize }}
      className={className}
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="truncate py-1" ref={cellRef}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        </TooltipTrigger>
        {isTruncated && (
          <TooltipContent align="start">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TooltipContent>
        )}
      </Tooltip>
    </TableCell>
  );
};

export function isTruncated(domElement: Element): boolean {
  return domElement.scrollWidth > domElement.clientWidth;
}

export function useIsTruncated<T extends Element>(
  ref: React.MutableRefObject<null | T>,
): boolean {
  const [isTruncatedState, setIsTruncatedState] = useState(false);

  useLayoutEffect(() => {
    if (ref.current !== null) {
      const isTextTruncated = isTruncated(ref.current);
      if (isTruncatedState !== isTextTruncated) {
        setIsTruncatedState(isTextTruncated);
      }
    }
  }, [isTruncatedState, ref]);

  return isTruncatedState;
}
