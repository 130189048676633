import { UTCDate } from "@date-fns/utc";
import {
  addMonths,
  addWeeks,
  differenceInDays,
  endOfMonth,
  format,
  getDaysInMonth,
  getISOWeek,
  isLastDayOfMonth,
  isThisMonth,
  parseISO,
  setDate,
  startOfMonth,
  subWeeks,
} from "date-fns";

export const formatInUTC = (date: Date | string, fmt: string) =>
  format(new UTCDate(date), fmt);

export const formatInLocalTime = (date: Date | string, fmt: string) =>
  format(new Date(date), fmt);

export const formatInDayMonth = (date: Date | string) =>
  format(new UTCDate(date), "dd MMM");

export const toLocalDate = (date: Date) => {
  const localDate = new Date(date);
  localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
  return localDate;
};

export const useCurrentAndNextMonthOptions = () => {
  const currentDate = new Date();

  const currentMonthFirstDate = startOfMonth(currentDate);
  const nextMonthFirstDate = startOfMonth(addMonths(currentDate, 1));

  const currentMonthOption = {
    label: format(currentDate, "MMMM"),
    value: format(currentMonthFirstDate, "yyyy-MM-dd"),
  };

  const nextMonthOption = {
    label: format(addMonths(currentDate, 1), "MMMM"),
    value: format(nextMonthFirstDate, "yyyy-MM-dd"),
  };

  return [currentMonthOption, nextMonthOption];
};

export const doDateRangesOverlap = (
  range1: { from: Date; to: Date },
  range2: { from: Date; to: Date },
) => {
  return range1.from <= range2.to && range1.to >= range2.from;
};

export const getDays = (monthDate: string) => {
  const pacingMonth = formatInUTC(monthDate, "yyyy-MM-dd");
  const isActualMonth = isThisMonth(parseISO(pacingMonth));

  const daysOfMonthPassed = differenceInDays(Date.now(), startOfMonth(Date.now()));

  const daysOfMonth = isActualMonth
    ? getDaysInMonth(Date.now())
    : getDaysInMonth(pacingMonth);

  const daysOfMonthRemaining = isActualMonth
    ? daysOfMonth - daysOfMonthPassed
    : differenceInDays(Date.now(), pacingMonth) <= 0
      ? daysOfMonth
      : 0;
  differenceInDays(Date.now(), startOfMonth(Date.now())) + 1;
  return { daysOfMonthPassed, daysOfMonth, daysOfMonthRemaining };
};

export const updateDateWithLastDayHandling = (
  date: Date,
  newYear: number,
  newMonth: number,
): Date => {
  const isOriginalLastDay = isLastDayOfMonth(date);
  const newMonthStart = new Date(newYear, newMonth, 1);
  const lastDayOfNewMonth = endOfMonth(newMonthStart).getDate();

  let newDay: number;

  if (isOriginalLastDay) {
    newDay = lastDayOfNewMonth;
  } else {
    const originalDay = date.getDate();
    newDay = Math.min(originalDay, lastDayOfNewMonth);
  }

  return setDate(newMonthStart, newDay);
};

export const getLastClosedISOWeek = () => {
  const today = new Date();
  return getISOWeek(subWeeks(today, 1));
};

export function getRequiredISOWeeks(numWeeks: number): string[] {
  const weeks: string[] = [];
  const currentDate = new Date();

  for (let i = 0; i < numWeeks; i++) {
    const date = addWeeks(currentDate, -numWeeks + 1 + i);
    const weekNumber = getISOWeek(date);
    weeks.push(weekNumber.toString());
  }

  return weeks;
}
