import { RowData, Table } from "@tanstack/react-table";
import { Button } from "@winclap-platform/ui/components/button";
import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
} from "lucide-react";

export const TablePaginator = <TData extends RowData>({
  table,
}: {
  table: Table<TData>;
}) => {
  const currentPage = table.getState().pagination.pageIndex + 1;
  return (
    table.getPageCount() > 1 && (
      <nav className="flex w-full items-center justify-end gap-2">
        <Button
          variant="icon"
          size="icon"
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.resetPageIndex()}
        >
          <ChevronsLeft className="size-5" />
        </Button>
        <Button
          variant="icon"
          size="icon"
          disabled={!table.getCanPreviousPage()}
          onClick={table.previousPage}
        >
          <ChevronLeft className="size-5" />
        </Button>
        <h4 className="px-4 py-2 text-sm tabular-nums">
          Page {currentPage} of {table.getPageCount()}
        </h4>
        <Button
          variant="icon"
          size="icon"
          onClick={table.nextPage}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight className="size-5" />
        </Button>
        <Button
          variant="icon"
          size="icon"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <ChevronsRight className="size-5" />
        </Button>
      </nav>
    )
  );
};
